
import Vue, { PropType } from 'vue';
import QuestionComponentFactory from '@/components/questionPassing/QuestionComponentFactory.vue';
import TTExpansionItem from '@/components/ui/TTExpansionItem.vue';
import { UpdateAnswersPayload, QuestionGroup } from './types';

export default Vue.extend({
  name: 'QuestionGroup',

  components: {
    QuestionComponentFactory,
    TTExpansionItem,
  },

  props: {
    group: {
      type: Object as PropType<QuestionGroup>,
      required: true,
    },
    coupleId: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    hasChildrenGroups(): boolean {
      return !!this.group.children?.length;
    },
    hasParentGroup(): boolean {
      return !!this.group.parentId;
    },
    isScaleTable(): boolean {
      return this.group.overallScale;
    },
    titleClass(): string {
      return this.hasParentGroup ? 'question-group__title_2' : 'question-group__title_1';
    },
  },

  methods: {
    onUpdateAnswers(payload: UpdateAnswersPayload) {
      this.$emit('update-answers', payload);
    },
  },
});
