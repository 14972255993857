
import Vue, { PropType } from 'vue';
import { V1EntitiesAssessmentObjectsPublicShowQuestion } from '@/services/api/tsxass';
import { AnswerAliases } from '@/constants/questionScaleAnswers';
import QuestionsLayout from '@/constants/questionsLayout';
import answerToArray from '@/helpers/answerToArray';
import QuestionScaleButtonAnswer from './QuestionScaleButtonAnswer.vue';
import QuestionCommentField from '../QuestionCommentField.vue';
import {
  Answer,
  AnswerButtonTypes,
  AnswerId,
  StyleObject,
} from './types';

interface AnswerColumn {
  answer: Answer;
  toggled: boolean;
  buttonStyle: StyleObject;
  buttonClass: string | Record<string, boolean>;
  showDescription: boolean;
  descriptionClass: string | Record<string, boolean>;
  descriptionStyle: StyleObject;
}

export default Vue.extend({
  name: 'QuestionScaleRating',

  components: {
    QuestionScaleButtonAnswer,
    QuestionCommentField,
  },

  props: {
    value: {
      type: [String, null] as PropType<AnswerId | null>,
      default: null,
    },
    question: {
      type: Object as PropType<V1EntitiesAssessmentObjectsPublicShowQuestion>,
      required: true,
    },
    buttonType: {
      type: String as PropType<AnswerButtonTypes>,
      default: AnswerButtonTypes.ICON_VALUE,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    hideAnswerDescriptions: {
      type: Boolean,
      default: false,
    },
    scaleGridStyle: {
      type: [Object, null] as PropType<StyleObject>,
      default: null,
    },
    hideComment: {
      type: Boolean,
      default: false,
    },
    coupleId: {
      type: Number,
      default: 0,
    },
    layout: {
      type: String,
      default: QuestionsLayout.HORIZONTAL,
    },
  },
  data() {
    return {
      comment: '',
    };
  },
  computed: {
    commentPayload(): any {
      return {
        questionUuid: this.question.questionUuid,
        answerIds: answerToArray(this.value),
        coupleId: this.coupleId,
      };
    },
    answers(): Answer[] {
      return this.question.answers;
    },
    answerIsSelected() {
      return !!this.value;
    },
    gridStyle(): StyleObject {
      if (this.scaleGridStyle) {
        return this.scaleGridStyle;
      }

      if (this.isVerticalLayout) {
        return {
          gridTemplateColumns: '108px 1fr',
          gridTemplateRows: 'auto',
          gridAutoFlow: 'row',
          gap: '24px',
          marginBottom: '24px',
        };
      }

      const cantAnswerBtnWidth = this.$vuetify.breakpoint.smAndUp ? '60px' : '50px';
      const val = `repeat(${this.scaleAnswerColumns.length}, 1fr) ${this.cantAnswerColumn ? cantAnswerBtnWidth : ''}`;
      return { gridTemplateColumns: val };
    },
    scaleAnswerColumns(): AnswerColumn[] {
      const scaleAnswers = this.answers.filter((answer) => answer.iconName !== AnswerAliases.CANT_ANSWER);
      const scaleAnswerColumns = scaleAnswers.map((answer, index) => {
        const isSelected = !!this.value && this.value === answer.id;
        const isFirstButton = index === 0;
        const isLastButton = index === scaleAnswers.length - 1;

        const showDescription = isSelected
          || (!this.answerIsSelected && (isFirstButton || isLastButton))
          || this.buttonType === AnswerButtonTypes.POINT;

        let gridColumn;
        // Если большой экран или не более трех ответов , то каждый занимает одну ячейку
        if (this.$vuetify.breakpoint.smAndUp || scaleAnswers.length <= 3) {
          gridColumn = `${index + 1} / ${index + 2}`;
          // Если мобильный экран
        } else {
          // Последняя кнопка занимает последние две ячейки
          // eslint-disable-next-line no-lonely-if
          if (isLastButton) {
            gridColumn = this.isVerticalLayout ? '1 / 1' : `${index} / ${index + 2}`;
            // Первая кнопка занимает первые две ячейки
          } else if (isFirstButton) {
            gridColumn = '1 / 3';
            // Кнопка выбранного ответа занимает две ячейки
          } else if (isSelected) {
            gridColumn = `${index + 1} / ${index + 3}`;
            // Остальные кнопки - одну ячейку
          } else {
            gridColumn = `${index + 1} / ${index + 2}`;
          }
        }

        const buttonStyle = this.isVerticalLayout
          ? { gridColumn: '1 / 1' }
          : { gridColumn: `${index + 1} / ${index + 2}`, gridRow: '1 / 1' };

        const descriptionStyle = this.isVerticalLayout
          ? { gridColumn: '2 / 2' }
          : { gridColumn, gridRow: '2 / 2' };

        return {
          answer,
          toggled: isSelected,
          buttonStyle,
          buttonClass: '',
          showDescription,
          descriptionClass: {
            'text-right': isLastButton && !this.isVerticalLayout && this.buttonType !== AnswerButtonTypes.POINT,
          },
          descriptionStyle,
        };
      });

      return scaleAnswerColumns;
    },
    cantAnswerColumn(): AnswerColumn | null {
      const answer = this.answers.find((item) => item.iconName === AnswerAliases.CANT_ANSWER);
      if (!answer) {
        return null;
      }

      const toggled = this.answerIsSelected && this.value === answer.id;

      const buttonStyle = this.isVerticalLayout
        ? { gridColumn: '1 / 1' }
        : { gridColumn: `${this.answers.length} / ${this.answers.length + 1}` };

      const descriptionStyle = this.isVerticalLayout
        ? { gridColumn: '2 / 2' }
        : { gridColumn: `${this.answers.length} / ${this.answers.length + 1}` };

      return {
        answer,
        toggled,
        buttonStyle,
        buttonClass: {
          'align-self-center': !this.isVerticalLayout,
          'ml-sm-2 ml-1': !this.scaleGridStyle && !this.isVerticalLayout,
        },
        showDescription: !this.answerIsSelected || toggled,
        descriptionStyle,
        descriptionClass: { 'ml-sm-2': !!this.scaleGridStyle },
      };
    },
    answerColumns(): AnswerColumn[] {
      return this.cantAnswerColumn ? [...this.scaleAnswerColumns, this.cantAnswerColumn] : this.scaleAnswerColumns;
    },
    isCommentFieldVisible(): boolean {
      return this.question?.needComment && !this.hideComment;
    },
    isCommentRequired(): boolean | undefined {
      return this.question?.requiredComment;
    },
    isVerticalLayout(): boolean {
      return this.layout === QuestionsLayout.VERTICAL;
    },
  },

  watch: {
    question: {
      immediate: true,
      handler(question: V1EntitiesAssessmentObjectsPublicShowQuestion) {
        this.comment = question.comment || '';
      },
    },
  },

  methods: {
    updateValue(value: Answer | null) {
      const answerId = value?.id || null;
      this.$emit('update-answers', {
        questionUuid: this.question.questionUuid,
        answerIds: answerToArray(answerId),
        comment: this.isCommentFieldVisible ? this.comment : null,
      });
      this.$emit('input', answerId);
    },
    onAnswerButtonClick(answer: Answer | null) {
      if (this.value === answer?.id) {
        this.updateValue(null);
      } else {
        this.updateValue(answer);
      }
      this.$refs.commentField?.debounceInput?.cancel();
    },

    onCommentInput(payload: any) {
      this.$emit('update-answers', {
        ...payload.payload,
        comment: payload.value,
      });
    },
  },
});
