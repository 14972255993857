
import Vue, { PropType } from 'vue';
import errorHandler from '@/helpers/errorHandler';
import TTDialogFullscreen from '@/components/ui/TTDialog/TTDialogFullscreen.vue';
import { tsxassApi } from '@/services/api';
import { PostV1CouplesIam } from '@/services/api/tsxass';
import { UserCardColumn } from '@/components/common/UserGroupTable/types';
import UserForRateGroupList from '@/components/assessmentChecklist/UserForRateGroupList.vue';
import { RatingStatusAliases } from '@/constants/userRatingStatuses';
import { UserCardColumnNames } from '@/constants/userForRateColumns';

export default Vue.extend({
  name: 'DialogAddingUsersForRate',

  components: {
    TTDialogFullscreen,
    UserForRateGroupList,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    couplesRequestWithoutParam: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  data() {
    return {
      iam: PostV1CouplesIam.EXPERT,
      selectedCoupleIds: [] as number[],
      addingToAssessmentedListLoading: false,
      RatingStatusAliases,
    };
  },

  computed: {
    columns(): UserCardColumn[] {
      return [
        {
          name: 'checkbox',
          width: '32px',
        },
        {
          name: UserCardColumnNames.EMPLOYEE,
          title: this.$t('userForRate.employee') as string,
          width: '3fr',
        },
        {
          name: UserCardColumnNames.TEAM,
          title: this.$t('userForRate.team') as string,
          width: '3fr',
        },
        {
          name: UserCardColumnNames.STATUS,
          title: this.$t('userForRate.status') as string,
          width: '2fr',
        },
        {
          name: UserCardColumnNames.ACTION,
          title: '',
          width: '3fr',
        },
      ];
    },
  },

  watch: {
    show: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.selectedCoupleIds = [];
          this.$refs.groupList?.loadCouples();
        }
      },
    },
  },

  methods: {
    async addToAssessmentedList() {
      try {
        this.addingToAssessmentedListLoading = true;

        await tsxassApi.postV1CouplesFavorite({
          surveyId: this.surveyId,
          coupleIds: this.selectedCoupleIds,
        });
        this.$emit('update:show', false);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.addingToAssessmentedListLoading = false;
      }
    },
    onCoupleReject(coupleId: number) {
      this.selectedCoupleIds = this.selectedCoupleIds.filter((id) => id !== coupleId);
    },
  },
});
