import QuestionType from '@/constants/questionType';
import {
  V1EntitiesAssessmentObjectsPublicShowQuestionAnswer,
  V1EntitiesAssessmentObjectsPublicShowQuestion,
} from '@/services/api/tsxass';
import { QuestionAnswer, AnswerId } from '@/components/questionPassing/scale/types';

type Props = Record<string, any>;

export const getChosenAnswerIds = (answers: V1EntitiesAssessmentObjectsPublicShowQuestionAnswer[]): AnswerId[] => {
  if (!answers) {
    return [];
  }

  return answers.filter((answer) => answer.chosen).map((answer) => answer.id);
};

const getChosenAnswerValue = (answers: V1EntitiesAssessmentObjectsPublicShowQuestionAnswer[]): AnswerId | null => {
  const chosenAnswer = answers.find((answer) => answer.chosen);
  return chosenAnswer?.id || null;
};

export const getPropsForScaleTable = (questions: V1EntitiesAssessmentObjectsPublicShowQuestion[]): Props => {
  const modelValue: QuestionAnswer[] = questions.map((question) => ({
    questionId: question.id,
    answerId: getChosenAnswerValue(question.answers),
  }));

  return {
    value: modelValue,
    questions,
    buttonType: questions?.[0]?.type,
  };
};

const getPropsForScale = (question: V1EntitiesAssessmentObjectsPublicShowQuestion): Props => ({
  value: getChosenAnswerValue(question.answers),
  question,
  buttonType: question.type,
  showLabel: true,
});

const getPropsForRadio = (question: V1EntitiesAssessmentObjectsPublicShowQuestion): Props => ({
  value: getChosenAnswerValue(question.answers),
  question,
});

const getPropsForCheckbox = (question: V1EntitiesAssessmentObjectsPublicShowQuestion): Props => ({
  value: getChosenAnswerIds(question.answers),
  question,
});

const getEmptyProps = (_question: V1EntitiesAssessmentObjectsPublicShowQuestion): Props => ({});

type SimpleQuestionType = Exclude<QuestionType, QuestionType.SCALE_TABLE>;

const PROPS_GETTERS_MAP: Record<
  SimpleQuestionType,
  (question: V1EntitiesAssessmentObjectsPublicShowQuestion) => Props
> = {
  [QuestionType.SCALE]: getPropsForScale,
  [QuestionType.CHECKBOX]: getPropsForCheckbox,
  [QuestionType.RADIO]: getPropsForRadio,
  [QuestionType.TEXT]: getEmptyProps,
};

export const getPropsForQuestionComponent = (
  questionType: SimpleQuestionType,
  question: V1EntitiesAssessmentObjectsPublicShowQuestion,
): Record<string, any> => {
  if (!questionType) {
    return {};
  }

  if (!question) {
    return {};
  }

  return PROPS_GETTERS_MAP[questionType](question);
};
