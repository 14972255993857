export enum AnswerButtonTypes {
  // В кнопке отображается иконка ответа
  ICON = 'ANSWER_TYPE_ICON',
  // В кнопке отображается значение ответа
  ICON_VALUE = 'ANSWER_TYPE_VALUE_ICON',
  // В кнопке отображается точка
  POINT = 'ANSWER_TYPE_POINT',
}

export type StyleObject = Record<string, string | undefined>

export type AnswerId = string

export interface Answer {
  id: AnswerId,
  value: string,
  description: string,
  iconName?: string,
}

export type QuestionId = number

export interface Question {
  id: QuestionId,
  name: string,
  answers: Answer[],
}

export interface QuestionAnswer {
  questionId: QuestionId,
  answerId: string | null,
}
