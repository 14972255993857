
import Vue, { PropType } from 'vue';
import { Location } from 'vue-router';
import { V1EntitiesCouplesPublicExpertIndexCouple } from '@/services/api/tsxass';
import UserAvatar from '@/components/common/UserAvatar.vue';
import { RatingStatusAliases } from '@/constants/userRatingStatuses';
import DialogRejectedComment from '../assessmentChecklist/DialogRejectedComment.vue';

export default Vue.extend({
  name: 'SidebarUserForRateCard',

  components: {
    UserAvatar,
    DialogRejectedComment,
  },

  inject: ['RouteNames'],

  props: {
    coupleData: {
      type: Object as PropType<V1EntitiesCouplesPublicExpertIndexCouple>,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    isTestingMode: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  data() {
    return {
      isDialogRejectedCommentShow: false,
    };
  },

  computed: {
    assessmentRoute(): Location | String {
      return this.isTestingMode
        ? ''
        : { name: this.RouteNames.R_APP_SURVEYEE_ASSESSMENT, params: { coupleId: String(this.coupleData.id) } };
    },
    isAlreadyRated(): boolean {
      return this.coupleData.status === RatingStatusAliases.DONE;
    },
  },

  methods: {
    onRejectClick() {
      this.isDialogRejectedCommentShow = true;
    },
    onUserClick() {
      if (this.isTestingMode) {
        this.$emit('demo-click');
      }
    },
  },
});
