import { render, staticRenderFns } from "./QuestionComponentFactory.vue?vue&type=template&id=1c20d233&scoped=true&"
import script from "./QuestionComponentFactory.vue?vue&type=script&lang=ts&"
export * from "./QuestionComponentFactory.vue?vue&type=script&lang=ts&"
import style0 from "./QuestionComponentFactory.vue?vue&type=style&index=0&id=1c20d233&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c20d233",
  null
  
)

export default component.exports