
import Vue, { VueConstructor, PropType } from 'vue';
import { VNavigationDrawer } from 'vuetify/lib';
import { mapGetters, mapState } from 'vuex';
import BannerAskForFeedback from '@/components/assessmentChecklist/BannerAskForFeedback.vue';
import SidebarUserForRateGroup from '@/components/assessmentSurvey/SidebarUserForRateGroup.vue';
import { V1EntitiesCouplesPublicExpertIndex } from '@/services/api/tsxass';
import { joinExpertGroupsWithCouples, GroupCouples } from '@/helpers/joinExpertGroupsWithCouples';
import TTLoader from '@/components/ui/TTLoader.vue';
import { SurveySettingNames } from '@/constants/SurveySettings';
import RouteNames from '@/plugins/vue-router/routeNames';
import { isExternalExpert } from '@/services/cookies';
import BannerAssessColleagues from './BannerAssessColleagues.vue';

export default Vue.extend({
  name: 'SidebarUsersForRate',

  components: {
    BannerAskForFeedback,
    SidebarUserForRateGroup,
    BannerAssessColleagues,
    TTLoader,
  },

  props: {
    navigationDrawer: {
      type: Boolean,
      default: false,
    },
    couplesData: {
      type: Object as PropType<V1EntitiesCouplesPublicExpertIndex>,
      default: () => ({ groups: [], couples: [] }),
    },
    activeCoupleId: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    askForFeedbackRoute: {
      type: [Object, null] as PropType<Location | null>,
      default: () => ({ name: RouteNames.R_APP_ASK_FOR_ASSESSMENT }),
    },
    isTestingMode: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    ...mapGetters('survey', {
      is360Survey: 'is360Survey',
      getSurveySettingValue: 'getSurveySettingValue',
    }),
    ...mapState('survey', {
      surveyMeta: 'meta',
      surveyMetaLoading: 'metaLoading',
    }),
    isBannerVisible(): boolean {
      return Boolean(
        !isExternalExpert.get()
          && (this.isTestingMode || this.getSurveySettingValue(SurveySettingNames.POSSIBLE_CHOOSE_EXPERTS)),
      );
    },
    componentTag(): string | VueConstructor<Vue> {
      return this.navigationDrawer ? VNavigationDrawer : 'div';
    },
    groupsWithCouples(): GroupCouples[] {
      if (this.is360Survey) {
        return joinExpertGroupsWithCouples(this.couplesData).filter((group) => !!group.couples.length);
      }

      return [
        {
          group: {
            id: 1,
            name: this.$t('assessmentFavorites.employeesForAssessment') as string,
            description: '',
            canAddSurveyee: false,
            canDeleteCouples: false,
            canReceiveCouplesOnMove: false,
          },
          couples: this.couplesData.couples,
        },
      ];
    },
  },
});
