
import Vue, { PropType } from 'vue';
import answerToArray from '@/helpers/answerToArray';
import { V1EntitiesAssessmentObjectsPublicShowQuestion } from '@/services/api/tsxass';
import { AnswerId } from './scale/types';

export default Vue.extend({
  name: 'QuestionRadioList',

  props: {
    value: {
      type: [String, null] as PropType<AnswerId | null>,
      default: null,
    },
    question: {
      type: Object as PropType<V1EntitiesAssessmentObjectsPublicShowQuestion>,
      required: true,
    },
  },

  methods: {
    onInput(answerId: AnswerId | null) {
      this.$emit('update-answers', { questionUuid: this.question.questionUuid, answerIds: answerToArray(answerId) });
      this.$emit('input', answerId);
    },
    onRadioClick(answerId: AnswerId | null) {
      if (answerId === this.value) {
        this.$emit('update-answers', { questionUuid: this.question.questionUuid, answerIds: [] });
      }
    },
  },
});
