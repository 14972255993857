
import Vue, { PropType } from 'vue';
import { V1EntitiesAssessmentObjectsPublicShowQuestion } from '@/services/api/tsxass';
import { AnswerId } from './scale/types';

export default Vue.extend({
  name: 'QuestionCheckboxList',

  props: {
    value: {
      type: Array as PropType<AnswerId[]>,
      default: () => [],
    },
    question: {
      type: Object as PropType<V1EntitiesAssessmentObjectsPublicShowQuestion>,
      required: true,
    },
  },

  data() {
    return {
      internalValue: [] as AnswerId[],
    };
  },

  computed: {
    maxAnswersNumberSelected(): boolean {
      return this.internalValue.length === this.question.answerCount;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.internalValue = newValue;
      },
    },
  },

  methods: {
    onInput(answerIds: AnswerId[]) {
      this.internalValue = answerIds;
      this.$emit('update-answers', { questionUuid: this.question.questionUuid, answerIds });
      this.$emit('input', answerIds);
    },
  },
});
