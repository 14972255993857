
import Vue, { PropType } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import PageTitle from '@/components/common/PageTitle.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import {
  V1EntitiesAudiencesPublicSearchIndexAudience,
  V1EntitiesAssessmentObjectsPublicShow,
  V1EntitiesAssessmentObjectsPublicShowQuestion,
  V1EntitiesAssessmentObjectsPublicShowGroup,
  V1EntitiesCouplesPublicExpertIndexCouple,
} from '@/services/api/tsxass';
import { getChosenAnswerIds } from '@/helpers/getPropsForQuestionComponent';
import getQuestionType from '@/helpers/getQuestionType';
import QuestionType from '@/constants/questionType';
import TTLoader from '@/components/ui/TTLoader.vue';
import SurveyType from '@/constants/surveyType';
import { RatingStatusAliases } from '@/constants/userRatingStatuses';
import QuestionGroup from './QuestionGroup.vue';
import { UpdateAnswersPayload, QuestionGroup as QuestionGroupType } from './types';

export default Vue.extend({
  name: 'SurveyAssessment',

  components: {
    PageTitle,
    UserAvatar,
    QuestionGroup,
    TTLoader,
  },

  inject: ['RouteNames'],

  props: {
    surveyee: {
      type: [Object, null] as PropType<V1EntitiesAudiencesPublicSearchIndexAudience | null>,
      default: null,
    },
    assessment: {
      type: Object as PropType<V1EntitiesAssessmentObjectsPublicShow>,
      default: () => ({ groups: [], questions: [] }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    completingLoading: {
      type: Boolean,
      default: false,
    },
    surveyType: {
      type: String as PropType<SurveyType>,
      default: SurveyType.SURVEY_360,
    },
    couple: {
      type: [Object, null] as PropType<V1EntitiesCouplesPublicExpertIndexCouple | null>,
      default: null,
    },
  },

  data() {
    return {
      localAssessment: {
        groups: [] as V1EntitiesAssessmentObjectsPublicShowGroup[],
        questions: [] as V1EntitiesAssessmentObjectsPublicShowQuestion[],
      },
      initialStatus: null as RatingStatusAliases | null,
    };
  },

  computed: {
    surveyeeFullName(): string {
      return this.surveyee ? `${this.surveyee.firstName} ${this.surveyee.lastName}` : '';
    },
    surveyTree(): QuestionGroupType[] {
      const groupsMap: Record<string, QuestionGroupType> = Object.fromEntries(
        this.localAssessment.groups.map((group) => [group.id, { ...group, children: [] }]),
      );
      const rootItems: QuestionGroupType[] = [];

      this.localAssessment.groups.forEach((item) => {
        const groupsMapItem = groupsMap[item.id];
        if (groupsMapItem.parentId) {
          groupsMap[groupsMapItem.parentId].children!.push(groupsMapItem);
        } else {
          rootItems.push(groupsMapItem);
        }
      });

      this.localAssessment.questions.forEach((item) => {
        if (!groupsMap[item.groupId].questions) {
          groupsMap[item.groupId].questions = [];
        }
        groupsMap[item.groupId].questions!.push(item);
      });

      return rootItems;
    },
    notFilledRequiredFields(): V1EntitiesAssessmentObjectsPublicShowQuestion[] {
      return this.localAssessment.questions.filter((q) => {
        if (!q.required && !q.requiredComment) {
          // все поля необязательные ->
          // вопрос не учитываем
          return false;
        }

        if ((!q.required && q.requiredComment) || getQuestionType(q) === QuestionType.TEXT) {
          // вопрос текстовый (открытый) или для вопроса обязательно наличие ТОЛЬКО коммента ->
          // -> вопрос учитываем при отсутствии комментария
          return !q.comment;
        }

        const chosenAnswers = getChosenAnswerIds(q.answers);

        if (q.required && q.requiredComment) {
          // сам вопрос обязательный, и у него обязательно наличие коммента ->
          // -> вопрос учитываем при отсутствии комментария или отсутствии выбранного варианта ответа
          return !chosenAnswers.length || !q.comment;
        }

        // сам вопрос обязательный, и у него необязательно наличие коммента ->
        // -> вопрос учитываем только при отсутствии выбранного варианта ответа
        return !chosenAnswers.length;
      });
    },
    completeButtonLabel(): string {
      if (this.initialStatus === RatingStatusAliases.DONE) {
        return this.$t('assessmentSurvey.changeEmployeeAssessment') as string;
      }

      return this.surveyType === SurveyType.SURVEY_360
        ? (this.$t('assessmentSurvey.completeAssessment') as string)
        : (this.$t('assessmentSurvey.completeEmployeeAssessment') as string);
    },
    showCompleteButton(): boolean {
      if (this.surveyType === SurveyType.SURVEY_360) {
        return this.couple?.status !== RatingStatusAliases.PENDING;
      }
      // @ts-ignore
      return ![RatingStatusAliases.DONE, RatingStatusAliases.PENDING].includes(this.couple?.status);
    },
    coupleId(): number | null {
      return this.couple?.id || null;
    },
  },

  watch: {
    assessment: {
      immediate: true,
      handler(newValue) {
        this.localAssessment = cloneDeep(newValue);
      },
    },
    coupleId: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.initialStatus = this.couple?.status as RatingStatusAliases;
        }
      },
    },
  },

  methods: {
    onUpdateAnswers(payload: UpdateAnswersPayload) {
      this.$emit('update-answers', payload);
      // Обновляем выбранные ответы и комментарии локально
      const questionIndex = this.localAssessment.questions.findIndex((q) => q.questionUuid === payload.questionUuid);
      if (questionIndex === -1) {
        return;
      }
      const newAnswers = this.localAssessment.questions[questionIndex].answers.map((answer) => ({
        ...answer,
        chosen: payload.answerIds.includes(answer.id),
      }));
      this.$set(this.localAssessment.questions[questionIndex], 'answers', newAnswers);
      this.$set(this.localAssessment.questions[questionIndex], 'comment', payload.comment);
    },
  },
});
