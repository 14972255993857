
import Vue, { PropType } from 'vue';
import { V1EntitiesAssessmentObjectsPublicShowQuestion } from '@/services/api/tsxass';
import { AnswerAliases } from '@/constants/questionScaleAnswers';
import {
  Question, Answer, AnswerButtonTypes, QuestionId, AnswerId, StyleObject, QuestionAnswer,
} from './types';
import QuestionScaleRating from './QuestionScaleRating.vue';

const CANT_ANSWER_BTN_WIDTH = '50px';

export default Vue.extend({
  name: 'QuestionScaleRatingTable',

  components: {
    QuestionScaleRating,
  },

  props: {
    value: {
      type: Array as PropType<QuestionAnswer[]>,
      default: () => [],
    },
    questions: {
      type: Array as PropType<V1EntitiesAssessmentObjectsPublicShowQuestion[]>,
      default: () => [],
    },
    buttonType: {
      type: String as PropType<AnswerButtonTypes>,
      default: AnswerButtonTypes.ICON_VALUE,
    },
    coupleId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      AnswerAliases,
    };
  },

  computed: {
    answers(): Answer[] {
      if (!this.questions.length) {
        return [];
      }

      const answers = [...this.questions[0].answers];
      const cantAnswerIndex = answers.findIndex((answer) => answer.iconName === AnswerAliases.CANT_ANSWER);
      // Если есть ответ "Не могу оценить", перемещаем его в конец
      if (cantAnswerIndex >= 0) {
        answers.push(answers.splice(cantAnswerIndex, 1)[0]);
      }
      return answers;
    },
    hasCantAnswer(): boolean {
      if (!this.answers.length) {
        return false;
      }

      return this.answers[this.answers.length - 1].iconName === AnswerAliases.CANT_ANSWER;
    },
    scaleAnswers(): Answer[] {
      return this.hasCantAnswer ? this.answers.slice(0, -1) : this.answers;
    },
    scaleGridStyle(): StyleObject {
      const val = `repeat(${this.scaleAnswers.length}, 1fr) ${this.hasCantAnswer ? CANT_ANSWER_BTN_WIDTH : ''}`;
      return {
        gridTemplateColumns: val,
        gap: '12px',
      };
    },
    selectedAnswersMap(): Record<QuestionId, AnswerId | null> {
      return Object.fromEntries(this.value.map((item) => [item.questionId, item.answerId]));
    },
    showAsGrid(): boolean {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },

  methods: {
    onAnswerButtonClick(question: Question, answerId: AnswerId) {
      const newAnswer = this.selectedAnswersMap[question.id] === answerId ? null : answerId;

      const newValue = [...this.value.filter((item) => item.questionId !== question.id)];
      newValue.push({ questionId: question.id, answerId: newAnswer });

      this.$emit('input', newValue);
    },
  },
});
