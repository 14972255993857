
import Vue, { PropType } from 'vue';
import {
  V1EntitiesExpertListsPublicIndexExpertList,
  V1EntitiesCouplesPublicExpertIndexCouple,
} from '@/services/api/tsxass';
import SectionTitle from '@/components/common/SectionTitle.vue';
import { CoupleRejectComment } from '@/components/assessmentChecklist/types';
import SidebarUserForRateCard from './SidebarUserForRateCard.vue';

export default Vue.extend({
  name: 'SidebarUserForRateGroup',

  components: {
    SectionTitle,
    SidebarUserForRateCard,
  },

  props: {
    group: {
      type: Object as PropType<V1EntitiesExpertListsPublicIndexExpertList>,
      required: true,
    },
    couples: {
      type: Array as PropType<V1EntitiesCouplesPublicExpertIndexCouple[]>,
      default: () => [],
    },
    activeCoupleId: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    isTestingMode: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  data() {
    return {
      hoverableCouple: null as V1EntitiesCouplesPublicExpertIndexCouple | null,
    };
  },

  computed: {
    // id пар, у которых не нужно выводить разделитель:
    // активная карточка и предыдущая, карточка под курсором и предыдущая, последняя карточка
    couplesWithoutDivider() {
      if (!this.couples.length) {
        return [];
      }

      const lastCoupleId = this.couples[this.couples.length - 1].id;

      const res = [this.activeCoupleId, lastCoupleId];

      const coupleBeforeActiveIndex = this.couples.findIndex((couple) => couple.id === this.activeCoupleId) - 1;
      const coupleBeforeHoverableIndex = this.couples.findIndex((couple) => couple.id === this.hoverableCouple?.id) - 1;

      if (coupleBeforeActiveIndex >= 0) {
        res.push(this.couples[coupleBeforeActiveIndex].id);
      }

      if (coupleBeforeHoverableIndex >= 0) {
        res.push(this.couples[coupleBeforeHoverableIndex].id);
        res.push(this.couples[coupleBeforeHoverableIndex + 1].id);
      }

      return res;
    },
  },

  methods: {
    onCoupleReject(coupleId: number, rejectedComment: string) {
      const payload: CoupleRejectComment = {
        coupleId,
        rejectedComment,
      };
      this.$emit('reject', payload);
    },
  },
});
