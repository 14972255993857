
import Vue, { PropType, VueConstructor } from 'vue';
import {
  V1EntitiesAssessmentObjectsPublicShowQuestion,
  PostV1CouplesCoupleIdAssessmentAnswer,
} from '@/services/api/tsxass';
import QuestionType from '@/constants/questionType';
import QuestionsLayout from '@/constants/questionsLayout';
import getQuestionType from '@/helpers/getQuestionType';
import {
  getPropsForScaleTable,
  getPropsForQuestionComponent,
  getChosenAnswerIds,
} from '@/helpers/getPropsForQuestionComponent';
import QuestionScaleRating from './scale/QuestionScaleRating.vue';
import QuestionScaleRatingTable from './scale/QuestionScaleRatingTable.vue';
import QuestionRadioList from './QuestionRadioList.vue';
import QuestionCheckboxList from './QuestionCheckboxList.vue';
import QuestionCommentField from './QuestionCommentField.vue';

const QUESTION_COMPONENT_MAP: Record<QuestionType, VueConstructor<Vue> | null> = {
  [QuestionType.SCALE]: QuestionScaleRating,
  [QuestionType.SCALE_TABLE]: QuestionScaleRatingTable,
  [QuestionType.CHECKBOX]: QuestionCheckboxList,
  [QuestionType.RADIO]: QuestionRadioList,
  [QuestionType.TEXT]: null,
};

export default Vue.extend({
  name: 'QuestionComponentFactory',
  components: {
    QuestionCommentField,
  },
  props: {
    question: {
      type: [Object, null] as PropType<V1EntitiesAssessmentObjectsPublicShowQuestion | null>,
      default: null,
    },
    // В случае, если компонент является представления сразу для нескольких вопросов (например, Таблица шкал)
    overallScale: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array as PropType<V1EntitiesAssessmentObjectsPublicShowQuestion[]>,
      default: () => [],
    },
    coupleId: {
      type: Number,
      default: 0,
    },
    layout: {
      type: String as PropType<QuestionsLayout>,
      default: QuestionsLayout.HORIZONTAL,
    },
  },

  data() {
    return {
      comment: '',
      QuestionType,
    };
  },

  computed: {
    commentPayload(): any {
      return {
        questionUuid: this.question.questionUuid,
        answerIds: getChosenAnswerIds(this.question.answers),
        coupleId: this.coupleId,
      };
    },
    questionType(): QuestionType | null {
      if (this.overallScale) {
        return QuestionType.SCALE_TABLE;
      }

      return getQuestionType(this.question);
    },
    isQuestionTypeText(): boolean {
      return this.questionType === QuestionType.TEXT;
    },
    isCommentFieldVisible(): boolean {
      return this.question?.needComment || this.isQuestionTypeText;
    },
    isCommentRequired(): boolean | undefined {
      return this.question?.requiredComment || (this.isQuestionTypeText && this.question?.required);
    },
    componentTag(): VueConstructor<Vue> | null {
      if (!this.questionType) {
        return null;
      }

      return QUESTION_COMPONENT_MAP[this.questionType] || null;
    },
    componentProps(): Record<string, any> {
      if (!this.questionType) {
        return {};
      }

      if (this.questionType === QuestionType.SCALE_TABLE) {
        return getPropsForScaleTable(this.questions || []);
      }

      if (!this.question) {
        return {};
      }

      return getPropsForQuestionComponent(this.questionType, this.question);
    },
    showLabel(): boolean {
      return !!this.question && ![QuestionType.SCALE, QuestionType.SCALE_TABLE].includes(this.questionType as any);
    },
  },

  watch: {
    question: {
      immediate: true,
      handler(question: V1EntitiesAssessmentObjectsPublicShowQuestion | null) {
        if (!question) {
          this.comment = '';
          return;
        }

        this.comment = question.comment || '';
      },
    },
  },

  methods: {
    updateAnswers(payload: PostV1CouplesCoupleIdAssessmentAnswer & { coupleId?: Number }) {
      this.$emit('update-answers', {
        ...payload,
        comment: payload.comment || this.comment || '',
        coupleId: payload.coupleId || this.coupleId,
        timestamp: new Date().getTime(),
      });
      this.$refs.commentField?.debounceInput?.cancel?.();
    },
    onCommentInput(payload: any) {
      this.updateAnswers({
        ...payload.payload,
        comment: payload.value,
      });
    },
  },
});
