
import Vue from 'vue';
import debounce from 'lodash/debounce';

export default Vue.extend({
  name: 'QuestionCommentField',

  props: {
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    isTextQuestion: {
      type: Boolean,
      default: false,
    },
    payload: {
      default: null,
      validator: () => true,
    },
  },

  data() {
    return {
      buttonIsClicked: !!this.value,
      rules: {
        required: (v: string) => !this.required || v.trim().length > 0 || this.$t('assessmentSurvey.commentIsRequired'),
      },
      isDebounced: false,
    };
  },

  watch: {
    payload: {
      deep: true,
      immediate: true,
      handler() {
        this.buttonIsClicked = !!this.value;
      },
    },
  },

  beforeDestroy() {
    if (this.isDebounced) {
      this.debounceInput.flush();
    }
  },

  created() {
    this.debounceInput = debounce(this.debounceInput, 1200);
  },
  methods: {
    debounceInput(event: any) {
      this.isDebounced = false;
      this.$emit('debounce-input', event);
    },
    onInput(event: any) {
      this.$emit('input', event);
      this.isDebounced = true;
      this.debounceInput({ payload: this.payload, value: event });
    },
  },
});
