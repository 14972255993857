
import Vue, { PropType } from 'vue';
import { AnswerAliases, QuestionScaleAnswerIconData } from '@/constants/questionScaleAnswers';
import QuestionsLayout from '@/constants/questionsLayout';
import { getScoreIconByName } from '@/helpers/getScoreIcon';
import { AnswerButtonTypes, Answer } from './types';

export default Vue.extend({
  name: 'QuestionScaleButtonAnswer',

  props: {
    answer: {
      type: Object as PropType<Answer>,
      required: true,
    },
    toggled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<AnswerButtonTypes>,
      default: AnswerButtonTypes.ICON_VALUE,
    },
    layout: {
      type: String,
      default: QuestionsLayout.HORIZONTAL,
    },
  },

  computed: {
    showDot(): boolean {
      return this.type === AnswerButtonTypes.POINT && !(this.isCantAnswerButton && this.isVerticalLayout);
    },
    showIcon(): boolean {
      return this.type === AnswerButtonTypes.ICON || this.isCantAnswerButton;
    },
    answerIcon(): QuestionScaleAnswerIconData | null {
      if (!this.showIcon || !this.answer.iconName) {
        return null;
      }

      return getScoreIconByName(this.answer.iconName as AnswerAliases);
    },
    isCantAnswerButton(): boolean {
      return this.answer.iconName === AnswerAliases.CANT_ANSWER;
    },
    buttonColor(): string {
      return this.toggled ? this.answerIcon?.color || 'tt-primary' : 'tt-light-mono-4';
    },
    iconColor(): string {
      if (this.toggled) {
        return 'tt-white';
      }

      return this.isCantAnswerButton ? 'tt-black' : this.answerIcon?.color || 'tt-black';
    },
    isVerticalLayout(): boolean {
      return this.layout === QuestionsLayout.VERTICAL;
    },
    isHorizontalLayout(): boolean {
      return this.layout === QuestionsLayout.HORIZONTAL;
    },
  },
});
