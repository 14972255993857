import { render, staticRenderFns } from "./QuestionRadioList.vue?vue&type=template&id=a37a52c2&scoped=true&"
import script from "./QuestionRadioList.vue?vue&type=script&lang=ts&"
export * from "./QuestionRadioList.vue?vue&type=script&lang=ts&"
import style0 from "./QuestionRadioList.vue?vue&type=style&index=0&id=a37a52c2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a37a52c2",
  null
  
)

export default component.exports