import QuestionType from '@/constants/questionType';
import AnswerType from '@/constants/answerType';
import { V1EntitiesAssessmentObjectsPublicShowQuestion } from '@/services/api/tsxass';

const QuestionKind = {
  OPEN: 'open',
};

export default (question: V1EntitiesAssessmentObjectsPublicShowQuestion | null): QuestionType | null => {
  if (!question) {
    return null;
  }

  if (question.kind === QuestionKind.OPEN) {
    return QuestionType.TEXT;
  }

  if (question.type === AnswerType.VALUE) {
    return question.answerCount === 1 ? QuestionType.RADIO : QuestionType.CHECKBOX;
  }

  if ([AnswerType.ICON, AnswerType.ICON_VALUE, AnswerType.POINT].includes(question.type as AnswerType)) {
    return QuestionType.SCALE;
  }

  return null;
};
